import React, { FC, Fragment, useCallback } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  CatalogItemType,
  ListItem,
  ListItemType,
  ListItemUpdateInput
} from 'generated-types.d';

import CheckoutListStore from 'stores/checkout-list/checkout-list-store';
import ToasterStore from 'stores/toaster-store/toaster-store';

import { breakpoints, fontSizes, space } from 'utils/rebass-theme';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ListItemColour } from 'features/lists/components/list-item-colour/list-item-colour';
import { ListItemValue } from 'features/lists/components/list-item-value/list-item-value';
import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import {
  getItemTotalPrice,
  getItemUnitPrice,
  getListCurrency,
  listItemSkuPrices
} from 'features/lists/lists.helpers';
import {
  DisabledItem,
  ListCellTitle,
  ListCellSubtitle
} from 'features/lists/lists.styles';
import { ListLayoutConfig } from 'features/lists/lists.types';

import QuantitySelector from 'components/quantity-selector';

import { ListItemMaxUnitPriceField } from '../max-unit-price-field/max-unit-price-field';

import { getListItemIncrement } from './checkout-list.helpers';
import {
  CheckoutTitleWrapper,
  QuantitySelectorWrapper,
  TotalPriceRange
} from './checkout-list.styles';

const CheckoutQuantitySelector: FC<{
  listItem: ListItem;
  checkoutListStore?: CheckoutListStore;
  toasterStore?: ToasterStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore,
  toasterStore: stores.toasterStore
}))(observer(({
  listItem,
  checkoutListStore,
  toasterStore
}) => {
  const increment = getListItemIncrement(listItem);

  const handleUpdateQuantity = async (value: number): Promise<void> => {
    try {
      await checkoutListStore!.updateListItem({
        item: listItem,
        updateInput: {
          quantity: value
        }
      });
    } catch (error) {
      toasterStore!.popErrorToast('the quantity for this item', 'update');
    }
  };

  const debouncedChangeHandler = useCallback(debounce(handleUpdateQuantity, 400), []);

  return (
    <QuantitySelectorWrapper width={130}>
      <QuantitySelector
        step={increment}
        min={increment}
        initialValue={listItem.quantity || 0}
        onChange={debouncedChangeHandler}
        size="responsive"
      />
    </QuantitySelectorWrapper>
  );
}));

export const CHECKOUT_LIST_FIELD_CONFIG: ListLayoutConfig = {
  Simple: {
    titleField: {
      key: 'itemName',
      heading: 'Item/SKU',
      renderValue: item => {
        const Component: FC = () => {
          const {
            flags: {
              wsFriendlyNames
            }
          } = useFeatureFlags();

          return (
            <ListCellTitle fontSize={fontSizes.small}>
              {wsFriendlyNames.isActive ? item?.catalogItem?.primaryName : item.sku}
            </ListCellTitle>
          );
        };

        return (
          <Component />
        );
      }
    },
    fields: [
      {
        key: 'quantity',
        heading: 'Quantity',
        renderValue: item => {
          return (
            <div
              css={css`
                margin-left: auto;
              `}
            >
              <Box width={130}>
                {item.quantity}
              </Box>
            </div>
          );
        }
      }
    ]
  },
  Advanced: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => {
        const Component: FC = () => {
          const {
            flags: {
              wsFriendlyNames
            }
          } = useFeatureFlags();

          const genus = item.catalogItem?.family?.genus;

          return (
            <CheckoutTitleWrapper>
              <ListCellTitle
                fontSize={fontSizes.small}
                style={{ fontWeight: 'bold' }}
              >
                {wsFriendlyNames.isActive ? (
                  item?.catalogItem?.primaryName || item.sku
                ) : (
                  item?.catalogItem?.title || item.sku
                )}
              </ListCellTitle>
              {!wsFriendlyNames.isActive && !!genus && (
                <ListCellSubtitle mt="4px">
                  <Flex>
                    <span>
                      {genus}
                    </span>
                    {!!item.colour && (
                      <Box
                        as="span"
                        ml="10px"
                      >
                        <ListItemColour
                          type="hit"
                          status="disabled"
                          colour={item.colour!}
                        />
                      </Box>
                    )}
                  </Flex>
                </ListCellSubtitle>
              )}
              {wsFriendlyNames.isActive && (
                <Fragment>
                  {item?.catalogItem?.secondaryName}
                </Fragment>
              )}
            </CheckoutTitleWrapper>
          );
        };

        return (
          <Component />
        );
      }
    },
    fields: [
      {
        key: 'stemLength',
        heading: 'Stem length',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 50%;
          }
        `,
        supportedTypes: [
          CatalogItemType.Flower,
          ListItemType.Flower
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeFlower':
                const hasStemLength = !!item?.minimumStemLength && item?.stemLengthUnit;

                return hasStemLength ? `${item.minimumStemLength}${item.stemLengthUnit}` : null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Stem length"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'potSize',
        heading: 'Pot size',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 50%;
          }
        `,
        supportedTypes: [
          CatalogItemType.Plant,
          ListItemType.Plant
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypePlant':
                return item?.potSize || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Pot size"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'weight',
        heading: 'Weight',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 50%;
          }
        `,
        supportedTypes: [
          CatalogItemType.Sundry,
          ListItemType.Sundry
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeSundry':
                return item?.weight || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Weight"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'height',
        heading: 'Height',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 50%;
          }
        `,
        supportedTypes: [
          CatalogItemType.Plant,
          ListItemType.Plant,
          CatalogItemType.Sundry,
          ListItemType.Sundry
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypePlant':
              case 'ListItemTypeSundry':
                return item?.height || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Height"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'maturity',
        heading: 'Maturity',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 50%;
          }
        `,
        supportedTypes: [
          CatalogItemType.Flower,
          ListItemType.Flower
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeFlower':
                return item?.maturity || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Maturity"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'quantity',
        heading: 'Quantity',
        customStyles: css`
          width: 20%;
          min-width: 145px;

          @media only screen and (max-width: ${breakpoints.medium}) {
            order: 2;
            min-width: unset;
          }
        `,
        renderValue: item => {
          return (
            <Box pr="10px">
              <CheckoutQuantitySelector
                listItem={item}
              />
            </Box>
          );
        }
      },
      {
        key: 'estimatedPrice',
        heading: 'Est. price',
        customStyles: css`
          width: 15%;

          @media only screen and (max-width: ${breakpoints.medium}) {
            order: 3;
            width: 50%;
            text-align: right;
          }
        `,
        renderValue: item => {
          const Component: FC<{
            checkoutListStore?: CheckoutListStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            checkoutListStore: stores.checkoutListStore
          }))(observer(({
            checkoutListStore
          }) => {
            const allPrices = listItemSkuPrices(item);

            switch (true) {
              case item.type === ListItemType.Custom: {
                const priceSubtotal = item.maxUnitPrice ? item.maxUnitPrice * (item.quantity || 1) : undefined;

                return (
                  <DisabledItem
                    css={css`
                      opacity: ${checkoutListStore!.listItemIdsBeingEdited.has(item.id) ? .4 : 1}
                    `}
                  >
                    <TotalPriceRange>
                      <ListPriceRange
                        min={priceSubtotal}
                        max={priceSubtotal}
                        currency={getListCurrency(item?.list)}
                      />
                    </TotalPriceRange>
                    <Fragment>
                      <ListPriceRange
                        min={item.maxUnitPrice || undefined}
                        max={item.maxUnitPrice || undefined}
                        currency={getListCurrency(item?.list)}
                      />
                      {' '}
                      <span>
                        /unit
                      </span>
                    </Fragment>
                  </DisabledItem>
                );
              }

              case !!allPrices?.length: {
                const allPricesUnitPrice = allPrices!.map(price => price.price);
                const allPricesSubtotal = allPrices!.map(price => price.price * (item.quantity || 1));

                const subtotalPriceMin = getItemTotalPrice(item, allPricesSubtotal, Math.min);
                const subtotalPriceMax = getItemTotalPrice(item, allPricesSubtotal, Math.max);
                const singlePriceMin = getItemUnitPrice(item, allPricesUnitPrice, Math.min);
                const singlePriceMax = getItemUnitPrice(item, allPricesUnitPrice, Math.max);

                return (
                  <DisabledItem
                    css={css`
                      opacity: ${checkoutListStore!.listItemIdsBeingEdited.has(item.id) ? .4 : 1}
                    `}
                  >
                    <TotalPriceRange>
                      <ListPriceRange
                        min={subtotalPriceMin}
                        max={subtotalPriceMax}
                        currency={getListCurrency(item?.list)}
                      />
                    </TotalPriceRange>
                    <Fragment>
                      <ListPriceRange
                        min={singlePriceMin}
                        max={singlePriceMax}
                        currency={getListCurrency(item?.list)}
                      />
                      {' '}
                      <span>
                        /unit
                      </span>
                    </Fragment>
                  </DisabledItem>
                );
              }

              default:

              case !allPrices?.length: {
                return <DisabledItem>--</DisabledItem>;
              }
            }
          }));

          return (
            <Component />
          );
        }
      },
      {
        key: 'maxUnitPrice',
        heading: 'Set max unit price',
        customStyles: css`
          width: 15%;
          min-width: 90px;

          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            order: 1;
            padding-bottom: ${space[3]}px;
            align-items: center;
          }
        `,
        renderValue: item => {
          const Component: FC<{
            checkoutListStore?: CheckoutListStore;
            toasterStore?: ToasterStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            checkoutListStore: stores.checkoutListStore,
            toasterStore: stores.toasterStore
          }))(observer(({
            checkoutListStore,
            toasterStore
          }) => {
            const allPrices = listItemSkuPrices(item);

            const updateListItem = useCallback(async (data: ListItemUpdateInput): Promise<void> => {
              try {
                await checkoutListStore!.updateListItem({
                  item: item,
                  updateInput: data
                });
              } catch (error) {
                toasterStore!.popErrorToast('the max unit price for this item', 'update');
              }
            }, []);

            switch (true) {
              case item.type === ListItemType.Custom: {
                return (
                  <Fragment>
                    <ListItemMaxUnitPriceField
                      listItem={item}
                      maxUnitPrice={item.maxUnitPrice}
                      maxAvailablePrice={0.10}
                      onUpdateListItem={updateListItem}
                      currency={getListCurrency(item?.list)!}
                      placeholder="0.10"
                    />
                  </Fragment>
                );
              }

              case !!allPrices?.length: {
                const allPricesInts = allPrices!.map(price => price.price);
                const max = Math.max(...allPricesInts);

                return (
                  <Fragment>
                    <ListItemMaxUnitPriceField
                      listItem={item}
                      maxUnitPrice={item.maxUnitPrice}
                      maxAvailablePrice={max}
                      onUpdateListItem={updateListItem}
                      currency={getListCurrency(item?.list)!}
                      placeholder={`${max.toFixed(2)}`}
                    />
                  </Fragment>
                );
              }

              default:

              case !allPrices?.length: {
                return <DisabledItem>--</DisabledItem>;
              }
            }
          }));

          return (
            <ListItemValue
              label="Set max unit price"
              value={<Component />}
            />
          );
        }
      }
    ]
  }
};
